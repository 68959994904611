#skillsSection {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.main-ctn2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.skills-column {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444;
}
.ctn-skill {
  width: 25%;
  margin: 2em;
  background-color: #fff;
  border: 3px solid black;
  box-shadow: 0.125rem 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.5);
  color: #fff;
}
.ctn-skill h2 {
  color: #53cf60;
  background-color: rgb(46, 46, 46);
  border-style: solid;
  border-width: 7px;
  border-image: linear-gradient(45deg, black, #53cf60, aliceblue, #444, black) 1;
  border-top: none;
  border-left: none;
  border-right: none;
  margin-top: 0;
}
.ctn-skill p {
  color: #444;
  margin-bottom: 0.5em;
}
.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo-tech {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.logo-tech img {
  margin: 0.5em;
}
@media (max-width: 1100px) {
  .skills-column {
    flex-direction: column;
  }
  .ctn-skill {
    width: 60%;
  }
}
@media (max-width: 850px) {
  .ctn-skill {
    width: 70%;
  }
  .logo-tech img {
    width: 50%;
  }
}
@media (max-width: 510px) {
  .ctn-skill h2 {
    font-size: 1.2em;
  }
}
