.Footer {
  display: flex;
  flex-direction: column;
  border-top: 3px solid black;
  background: #57ff68;
  color: black;
  width: 100%;
}
.Footer h2 {
  margin-bottom: 0;
}
.Footer p {
  margin-top: 0.5em;
  margin-bottom: 0;
}
.legal-btn {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.legal {
  color: #444;
  text-decoration: none;
}
.legal:hover {
  text-decoration: underline;
}
.logo {
  width: 3%;
  margin-right: 0.6em;
  margin-bottom: 1em;
}
@media (max-height: 868px) {
  .Footer h2 {
    font-size: 1em;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .Footer p {
    font-size: 0.8em;
    margin: 0.2em;
  }
}
@media (max-width: 850px) {
  .Footer h2 {
    font-size: 1em;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .Footer p {
    font-size: 0.8em;
    margin: 0.2em;
  }
  .legal {
    font-size: 0.8em;
  }
  .logo {
    width: 6%;
  }
}
@media (max-width: 400px) {
  .Footer h2 {
    font-size: 0.7em;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .Footer p {
    font-size: 0.6em;
    margin: 0.2em;
  }
  .logo {
    width: 4%;
  }
}
