.About {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.main-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #444;
  padding: 2em;
}
.bd {
  display: flex;
  flex-direction: column;
  margin-top: 2.4em;
  margin-bottom: 2.5em;
}
.img-ctn {
  width: 100%;
  height: 100%;
  transform: skew(1deg, 1deg);
}

.img-ctn img {
  max-width: 450px;
  width: 100%;
  max-height: 450px;
  border: 4px solid black;
  transform: skew(0deg, -1deg);
  box-shadow: 0.125rem 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.5);
  background-color: #fff;
}

.Intro {
  width: 50%;
  height: auto;
  margin-top: 2.4em;
  margin-bottom: 2.5em;
  padding: 1em;
  box-shadow: 0.125rem 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border: 4px solid black;
  transform: skew(1deg, 0deg);
  justify-content: center;
  margin-right: 1em;
}

.Intro h1 {
  color: #5da264;
  font-size: 2em;
  height: auto;
  width: 60%;
  display: flex;
  flex-direction: row;
  line-height: 2.75rem;
  text-align: left;
  margin: 0;
  margin-bottom: 1em;
  margin-left: 0.2em;
  border-style: solid;
  border-width: 3px;
  border-image: linear-gradient(to right, white, black, white) 1;
  border-top: none;
  border-left: none;
  border-right: none;
}

.ctn-cta p {
  text-align: left;
  font-size: 1.3em;
  margin: 0;
  margin-left: 0.5em;
  margin-bottom: 1em;
  line-height: 1.25rem;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cta {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.2em;
  padding: 0.5em;
  transform: skew(2deg, 0deg);
  background-color: white;
  border: 6px solid black;
  box-shadow: 0.125rem 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.5);
  font-size: 1.5em;
  transition: transform 0.5s;
}
.cta a {
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 0.5em;
  color: black;
}

.cta:hover {
  transform: skew(0deg, 1deg) scale(1.1);
}

.forBing {
  display: none;
}
@media (max-width: 1100px) {
  .main-card {
    flex-direction: column-reverse;
    padding: 0;
  }
  .Intro {
    width: 60%;
    margin: 0;
    margin-bottom: 1em;
  }
  .bd {
    margin: 1em;
  }
}
@media (max-width: 850px) {
  .Intro {
    width: auto;
    height: auto;
    margin-top: 0;
    margin-left: 1em;
    margin-right: 1em;
  }
}

/* Pour les smartphones (écrans plus petits) */
@media (max-width: 700px) {
  .cta a {
    margin-bottom: 0;
  }
}
@media (max-width: 510px) {
  .main-card {
    flex-direction: column;
  }
  .bd {
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
  }
  .img-ctn {
    width: 90%;
  }
  .main-card {
    height: auto;
    padding: 0.5rem;
  }
  .Intro h1 {
    font-size: 1.3em;
    line-height: 1.3rem;
  }
  .Intro p {
    font-size: 1em;
  }
  .cta {
    font-size: 1.4em;
  }
}
@media (max-width: 300px) {
  .cta {
    font-size: 1.2em;
  }
}
