.legal-part {
  background-color: #444;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.legal-part h1 {
  color: aliceblue;
  padding-top: 0.5em;
}
.legal-card {
  border: 3px solid black;
  background: #fff;
  box-shadow: 0.125rem 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.5);
  width: 60%;
  margin-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  transform: skew(1deg, 0deg);
}
@media (max-width: 510px) {
  .legal-card {
    width: 60%;
    transform: skew(0.2deg, 0deg);
  }
  .legal-part h1 {
    font-size: 1.3em;
  }
  .legal-part h2 {
    font-size: 1.2em;
  }
}
