.progress-bar {
  width: 80%;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-top: 0;
  margin-bottom: 0.5em;
  border: 2px solid black;
}

.progress {
  height: auto;
  background-color: #3db949;
  border-radius: 3px;
  transition: width 0.5s ease-in-out;
}
.progress-text {
  color: black;
}
