.NotFound-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.card {
  background-color: #444;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 6em;
  padding-bottom: 6em;
}
.NotFound {
  background-color: #fff;
  border: 3px solid black;
  box-shadow: 0.125rem 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.5);
  color: #53cf60;
  transform: skewY(1deg);
  width: auto;
  height: auto;
  padding: 1em;
}
.NotFound h1 {
  font-size: 10em;
}
.NotFound p {
  color: #444;
  font-size: 1.5em;
}

@media (max-width: 850px) {
  .card {
    padding-top: 3em;
    padding-bottom: 3em;
  }
  .NotFound {
    padding: 1.5em;
  }
  .NotFound h1 {
    font-size: 5em;
  }
  .NotFound p {
    color: #444;
    font-size: 1em;
  }
}
@media (max-width: 500px) {
  .card {
    padding-top: 5em;
    padding-bottom: 5.5em;
  }
  .NotFound {
    width: 60%;
  }
}
