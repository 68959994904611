.email-ctn {
  background-color: #444;
  display: flex;
  height: 41em;
  flex-direction: column;
  align-items: center;
}
.email-card {
  background-color: #fff;
  border: 3px solid black;
  box-shadow: 0.125rem 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.5);
  width: 60%;
  height: 20em;
  margin: 4em;
  transform: skew(1.5deg, 0deg);
}
.email {
  margin: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.email h1 {
  color: #53cf60;
}
.retry {
  text-decoration: none;
  padding: 0.5em;
  margin-top: 0.5em;
  background-color: #fff;
  border: 3px solid black;
  color: black;
  font-size: 1em;
  transition: background-color 1s, transform 1s;
  cursor: pointer;
}
.retry:hover {
  transform: skew(0deg, 1deg) scale(1.1);
}
