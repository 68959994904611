.App {
  text-align: center;
  margin: 0;
  padding: 0;
}

.element {
  min-height: 100vh;
}

.pageContent {
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #797979;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.7);
}
