@import url("https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap");

.Header-ctn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid #57ff68;
  background-color: rgba(0, 0, 0, 0.92);
}
.Header {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: auto;
  line-height: 0;
}

.Header h1 {
  width: auto;
  color: aliceblue;
  font-size: 2em;
  margin-bottom: 0.2em;
  margin-top: 0.6em;
  margin-left: 1em;
  line-height: 1em;
  font-family: "Lato", sans-serif;
  border-style: solid;
  border-width: 2px;
  border-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.92),
      #57ff68,
      aliceblue,
      rgba(0, 0, 0, 0.92),
      rgba(0, 0, 0, 0.92)
    )
    1;
  border-top: none;
  border-left: none;
  border-right: none;
}
.Header p {
  color: #53cf60;
  margin-top: 0.5em;
  padding-bottom: 1em;
}

@media (max-width: 1150px) {
  .Header h1 {
    font-size: 1.5em;
  }
}
@media (max-width: 800px) {
  .Header-ctn {
    flex-direction: column;
  }
  .Header {
    align-items: center;
  }
  .Header p {
    margin-bottom: 0;
  }
}
@media (max-width: 510px) {
  .Header {
    margin-bottom: 0;
  }
  .Header h1 {
    font-size: 1rem;
  }
  .Header p {
    font-size: 0.7em;
  }
}

@media (max-width: 330px) {
  .Header h1 {
    font-size: 0.9rem;
  }
}
