#worksSection {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.grid-ctn {
  display: flex;
  justify-content: center;
  background-color: #444;
  width: 100%;
}
.works-grid {
  width: 75%;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(2, auto);
  gap: 2em;
  padding: 2.3em;
}
.work-card {
  position: relative;
  background-color: #fff;
  border: 3px solid black;
  box-shadow: 0.125rem 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.5);
  height: auto;
  width: auto;
  padding-bottom: 0.5em;
  transform: skew(1deg, 0.5deg);
  margin-top: 2.4em;
  margin-bottom: 2.5em;
  transition: transform 0.5s;
}
.work-card h2 {
  border-style: solid;
  border-width: 7px;
  border-image: linear-gradient(45deg, black, #53cf60, aliceblue, #444, black) 1;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #53cf60;
  background-color: rgb(46, 46, 46);
  margin-top: 0;
}
.work-card img {
  max-width: 400px;
  max-height: 197px;
  width: 100%;
  border: 3px solid black;
  transform: skew(1deg, 0deg);
  margin: 0.5em;
  margin-top: 0;
}
.work-card:hover {
  transform: scale(1.1);
}
.work-card p {
  color: #444;
  text-align: left;
  margin: 0.625rem;
  margin-left: 0.9375rem;
  line-height: 0.9rem;
}
@media (max-width: 1200px) {
  .work-card img {
    width: 90%;
  }
}
@media (max-width: 1100px) {
  .works-grid {
    grid-template-columns: repeat(1, auto);
  }
}
@media (max-width: 650px) {
  .work-card img {
    width: 90%;
  }
}
@media (max-width: 510px) {
  .work-card img {
    width: 90%;
    padding: 0;
  }
  .work-card h2 {
    font-size: 1.2em;
  }
}
@media (max-width: 400px) {
  .work-card img {
    width: 90%;
  }
}
