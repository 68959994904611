#contactSection {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.contact {
  width: 100%;
  background-color: #444;
  display: flex;
  justify-content: center;
  padding-top: 2em;
  padding-bottom: 2em;
}

#contactForm {
  border: 3px solid black;
  width: 80%;
  max-width: 37.5rem;
  margin-top: 2.4em;
  margin-bottom: 2.5em;
  padding: 2em;
  background: #fff;
  box-shadow: 0.125rem 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.5);
  text-align: left;
  transform: skew(1.5deg, 0deg);
}
.contact h2 {
  color: black;
  margin-top: 0;
}
.form-group {
  margin-bottom: 0;
}

.form-group label {
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: black;
  font-size: 1.2em;
}

/* Styles pour les champs input et textarea */
.form-group input[type="email"],
.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 1rem;
  border: 3px solid #53cf60;
  box-sizing: border-box;
  resize: vertical;
}
.form-group textarea {
  height: 10em;
}
.formError {
  display: none;
  color: red;
}
.form-group input[type="submit"] {
  padding: 0.5em;
  margin-top: 0.5em;
  background-color: #fff;
  border: 3px solid black;
  color: black;
  font-size: 1em;
  transition: transform 1s;
  cursor: pointer;
}

.form-group input[type="submit"]:hover {
  transform: skew(0deg, 1deg) scale(1.1);
}
.form-group input[type="email"]:focus,
.form-group input[type="text"]:focus,
.form-group textarea:focus {
  border-radius: 0;
  border: 3px solid #444;
}
@keyframes pulse {
  0%,
  100% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}
.loading-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: black;
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in-out;
}

.loading-dot:nth-child(1) {
  animation-delay: 0s;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.5s;
}

.loading-dot:nth-child(3) {
  animation-delay: 1s;
}
@media (max-width: 850px) {
  .contact h2 {
    font-size: 1.2em;
  }
  .form-group textarea {
    height: 12em;
  }
  .grecaptcha-badge {
    transform: scale(0.8);
  }
}
@media (max-width: 580px) {
  #contactForm {
    width: 70%;
    transform: skew(1deg, 0deg);
  }
  .contact h2 {
    font-size: 1em;
  }
  .form-group {
    margin-bottom: 0.5em;
  }
  .form-group textarea {
    height: 20em;
  }
  .form-group input[type="email"],
  .form-group input[type="text"] {
    width: 100%;
    padding: 0.5rem;
    resize: vertical;
  }
  .form-group label {
    font-size: 0.8em;
  }
}
