.ctn-header {
  width: auto;
}

.ctn-header .nav {
  height: auto;
  display: flex;
  padding: 1em;
  flex-direction: row;
  overflow: hidden;
  color: aliceblue;
}

.ctn-header .nav .nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ctn-header .nav .nav-links .links {
  margin: 0 0.625rem;
}

.links a {
  text-decoration: none;
  color: aliceblue;
  font-size: 1.1rem;
  padding: 0.625rem;
  margin: 0;
  cursor: pointer;
  display: block;
  transition: transform 1s;
}
.links a:hover {
  transform: skew(0deg, 1deg) scale(1.1);
  background-color: #fff;
  color: black;
}

@media (max-width: 850px) {
  .ctn-header .nav {
    width: 100%;
    height: 3em;
    padding: 0em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 0;
  }
}

@media (max-width: 510px) {
  .ctn-header .nav .nav-links .links a {
    font-size: 0.8rem;
  }
}

@media (max-width: 380px) {
  .ctn-header .nav .nav-links .links a {
    font-size: 0.7rem;
    padding: 0.35rem;
  }
  .ctn-header .nav .nav-links .links {
    margin: 0 0.2rem;
  }
}
